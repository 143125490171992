/* eslint-disable */
import errorHandler from './errorHandler'

export default request => {
  return request
    .then(async response => {
      const data = await response.json().catch(error => {
        return { data: false, error }
      })

      return response.ok ? { data, error: false } : { data: false, error: errorHandler(data) }
    })
    .catch(error => {
      let errorMessage = ''

      if (error.name === 'AbortError') {
        errorMessage = 'The Request has Timed Out'
      }

      return { data: false, error: errorMessage }
    })
}
