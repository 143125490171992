// api config
import { API_URL } from '../api/config'

// register and login
export const apiRegister = () => `${API_URL}/register`
export const apiUserAuth = () => `${API_URL}/login`

// user
export const apiGetUser = (userId) => `${API_URL}/user/${userId}`
export const apiGetAllUsers = () => `${API_URL}/users`
export const apiUpdateUser = (userId) => `${API_URL}/user/${userId}`
export const apiDeleteUser = (userId) => `${API_URL}/user/${userId}`
export const apiUpdateUserPassword = () => `${API_URL}/user/password`

// locations
export const apiGetLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiUpdateLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiDeleteLocation = (locationId) => `${API_URL}/locations/${locationId}`
export const apiGetLocations = () => `${API_URL}/locations`
export const apiAddLocation = () => `${API_URL}/locations`

// sub locations
export const apiGetSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiUpdateSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiDeleteSubLocation = (subLocationId) => `${API_URL}/sublocations/${subLocationId}`
export const apiGetSubLocations = () => `${API_URL}/sublocations`
export const apiAddSubLocation = () => `${API_URL}/sublocations`
export const apiGetSubLocationsByLocationId = () => `${API_URL}/location_sublocations`

// bookings
export const apiGetAllBookingsByLocation= (locationId) => `${API_URL}/bookings?location_id=${locationId}`
export const apiAddBooking = () => `${API_URL}/bookings`
export const apiGetAllBookings = () => `${API_URL}/bookings`
export const apiGetOneBooking = (bookingId) => `${API_URL}/bookings/${bookingId}`
export const apiUpdateBooking = (bookingId) => `${API_URL}/bookings/${bookingId}`
export const apiDeleteBooking = (bookingId) => `${API_URL}/bookings/${bookingId}`
export const apiBookingsReport = () => `${API_URL}/bookings/allstats/stats`
export const apiCountAllBookings = () => `${API_URL}/bookings/count`

// media
export const apiGetMediaById = (mediaId) => `${API_URL}/media_by_id?id=${mediaId}`
export const apiDeleteMedia = (mediaId) => `${API_URL}/media_delete?id=${mediaId}`
export const apiUpdateMedia = (mediaId) => `${API_URL}/media_update?id=${mediaId}`
export const apiAllMedia = () => `${API_URL}/media`
export const apiAddMedia = () => `${API_URL}/media`

// disclaimers
export const apiGetDisclaimerById = (disclaimerId) => `${API_URL}/disclaimer_by_id?id=${disclaimerId}`
export const apiDeleteDisclaimer = (disclaimerId) => `${API_URL}/disclaimer_delete?id=${disclaimerId}`
export const apiUpdateDislclaimer = (disclaimerId) => `${API_URL}/disclaimer_update?id=${disclaimerId}`
export const apiAllDisclaimers = () => `${API_URL}/disclaimers`
export const apiAddDisclaimer = () => `${API_URL}/disclaimers`

export const apiEmail = () => `https://ing0g4m48e.execute-api.us-east-1.amazonaws.com/vixalopexstageapi/vixalopexlambdaresource`

export const apiConfirmationEmail = () => `https://c5q5ekr5zi.execute-api.us-east-1.amazonaws.com/dev/ebsmailer`

// stripe
export const apiGetStripeSecretKey = (price) => `${API_URL}/stripe?price=${price}`