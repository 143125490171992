// stripe action constants
import {
    STRIPE_GET_SECRET_KEY_SUCCESS,
  } from "../actions/types";
  
  // default stripe state
  const defaultState = {
    secretKey: "",
  };
  
  // email reducer
  export default function init(state = defaultState, action) {
    switch (action.type) {
  
      case STRIPE_GET_SECRET_KEY_SUCCESS:
        return {
          ...state,
          secretKey: action.payload,
        };
  
      default:
        return state;
    }
  };
  