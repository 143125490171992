//user
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const CURRENT_USER = "CURRENT_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

//ui
export const DESTROY_SESSION = "DESTROY_SESSION";
export const UI_SET_ALERT = "UI_SET_ALERT";
export const UI_SET_CONTENT = "UI_SET_CONTENT";
export const UI_OPEN_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_CLOSE_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_SET_ACTIVE_COMPONENT = "UI_SET_ACTIVE_COMPONENT";
export const UI_SET_ACTIVE_ADMIN_COMPONENT = "UI_SET_ACTIVE_ADMIN_COMPONENT";

//date
export const UI_SET_DATE = "UI_SET_DATE";

//email
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';

//bookings
export const BOOKING = 'BOOKING';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const BOOKINGS_REPORT = "BOOKINGS_REPORT";

//location
export const LOCATION = "LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const LOCATIONS_SUCCESS = "LOCATION_SUCCESS";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"

//sublocation
export const SUBLOCATION = "SUBLOCATION";
export const CREATE_SUBLOCATION_SUCCESS = "CREATE_SUBLOCATION_SUCCESS";
export const SUBLOCATIONS_SUCCESS = "SUBLOCATIONS_SUCCESS";
export const UPDATE_SUBLOCATION_SUCCESS = "UPDATE_SUBLOCATION_SUCCESS";
export const DELETE_SUBLOCATION_SUCCESS = "DELETE_SUBLOCATION_SUCCESS";

//media
export const MEDIA = "MEDIA";
export const CREATE_MEDIA_SUCCESS = "CREATE_MEDIA_SUCCESS";
export const ALL_MEDIA_SUCCESS = "ALL_MEDIA_SUCCESS";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";

//disclaimers
export const DISCLAIMER = "DISCLAIMER";
export const CREATE_DISCLAIMER_SUCCESS = "CREATE_DISCLAIMER_SUCCESS";
export const ALL_DISCLAIMERS_SUCCESS = "ALL_DISCLAIMERS_SUCCESS";
export const UPDATE_DISCLAIMER_SUCCESS = "UPDATE_DISCLAIMER_SUCCESS";
export const DELETE_DISCLAIMER_SUCCESS = "DELETE_DISCLAIMER_SUCCESS";

export const STRIPE_GET_SECRET_KEY_SUCCESS = 'STRIPE_GET_SECRET_KEY_SUCCESS'