// user action constants
import { UI_SET_ACTIVE_ADMIN_COMPONENT} from '../types'

export const setActiveAdminComponent = (component, props = {}) => ({
  type: UI_SET_ACTIVE_ADMIN_COMPONENT,
  payload: {
    component,
    props
  }
})
