// user action constants
import { UI_CLOSE_CUSTOM_DIALOG } from '../types'

export const closeCustomDialog = () => ({
  type: UI_CLOSE_CUSTOM_DIALOG,
  payload: {
    open: false,
    component: '',
    props: {}
  }
})