/* eslint-disable */
// user action constants
import {
    MEDIA,
    CREATE_MEDIA_SUCCESS,
    ALL_MEDIA_SUCCESS,
    UPDATE_MEDIA_SUCCESS,
    DELETE_MEDIA_SUCCESS
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    media: {
      id: null,
      file_name: null,
      file_type: null,
      file_path: null
    },
    newMedia: {},
    allMedia: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case MEDIA:
        return {
          ...state,
          media: {
            id: action.payload.id,
            file_name: action.payload.file_name,
            file_type: action.payload.file_type,
            file_path: action.payload.file_path
          }
        }

      case ALL_MEDIA_SUCCESS:
        return {
          ...state,
          allMedia: action.payload
        }

      case CREATE_MEDIA_SUCCESS:
        const existingArray = state.allMedia;
        existingArray.push(action.payload);
        return {
          ...state,
          newMedia: existingArray
        }
  
      case UPDATE_MEDIA_SUCCESS:
        const filteredArray = state.allMedia.filter(media => media.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          allMedia: filteredArray,
        }

      case DELETE_MEDIA_SUCCESS:
        const filteredData = state.allMedia.filter(media => media.id !== action.payload)
        return {
          ...state,
          allMedia: filteredData
        }
    

      default:
        return state;
    }
  };
  