/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { openCustomDialog } from "../../../config/reduxstore/actions/ui/openCustomDialog"

import styles from "../../../assets/footerStyle";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const dispatch = useDispatch();

  const handleContact = () => {
    dispatch(
      openCustomDialog("dialogs/Contact")
    )
  };

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Typography
                onClick={handleContact}
                className={classes.block}
              >
                Contact
              </Typography>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <a
                href="http://localhost:3000"
                className={classes.block}
                target="_blank"
              >
                About
              </a>
            </ListItem> */}
          </List>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Typography
                className={classes.block}
              >
                &copy; {1900 + new Date().getYear()}, created by Vixalopex LLC
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
