import "react-hot-loader";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
// libraries
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
// app state
import store from './config/reduxstore/store/configureStore'
// components
import App from "./App";
Amplify.configure(awsExports);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);