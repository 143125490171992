import { container } from "./baseStyles";
  
  const youtubeStyle = {
    section: {
      padding: "70px 0"
    },
    container,
    marginAuto: {
      marginLeft: "auto !important",
      marginRight: "auto !important"
    }
  };
  
  export default youtubeStyle;