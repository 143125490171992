/* eslint-disable */
// user action constants
import {
    BOOKING,
    CREATE_BOOKING_SUCCESS,
    BOOKINGS_SUCCESS,
    UPDATE_BOOKING_SUCCESS,
    DELETE_BOOKING_SUCCESS,
    BOOKINGS_REPORT
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    booking: {
        id: null,
        title: null,
        startDate: null,
        endDate: null,
        location_id: null,
        sub_location_id: null,
        notes: null
    },
    newBooking: {},
    bookings: [],
    bookingsReport: [],
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case BOOKING:
        return {
          ...state,
          booking: {
            id: action.payload.id,
            title: action.payload.name,
            startDate: action.payload.startDate,
            endDate: action.payload.BAYS_SUCCESSendDate,
            location_id: action.payload.location_id,
            sub_location_id: action.payload.sub_location_id,
            notes: action.payload.notes
          }
        }

      case BOOKINGS_SUCCESS:
        return {
          ...state,
          bookings: action.payload
        }

      case CREATE_BOOKING_SUCCESS:
        const existingArray = state.bookings;
        existingArray.push(action.payload);
        return {
          ...state,
          newBooking: action.payload,
          bookings: existingArray
        }

      case UPDATE_BOOKING_SUCCESS:
        const filteredArray = state.bookings.filter(booking => booking.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          bookings: filteredArray,
        }

      case DELETE_BOOKING_SUCCESS:
        const filteredData = state.bookings.filter(booking => booking.id !== action.payload)
        return {
          ...state,
          bookings: filteredData
        }
    
      case BOOKINGS_REPORT:
        return {
          ...state,
          bookingsReport: action.payload
        }

      default:
        return state;
    }
  };
  