import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useIsMounted } from '../../config/utilities/hooks/useIsMounted'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Portal from '@material-ui/core/Portal'
import { setAlert } from '../../config/reduxstore/actions/ui/setAlert'

export default function Alert() {
  const isMounted = useIsMounted()
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const alert = useSelector(state => state.ui.alert)
  const [messageInfo, setMessageInfo] = useState(undefined)

  useEffect(() => {
    if (isMounted) {

      if (open && !alert.message) {
        setOpen(false)
      }

      if (alert.message) {
        setMessageInfo({
          message: alert.message,
          type: alert.type,
          key: new Date().getTime()
        })
        setOpen(true)
      }
    }
  }, [alert, isMounted, open])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    dispatch(setAlert('', ''))
  }

  return (
    <Portal>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={0}
          variant="filled"
          onClose={handleClose}
          severity={messageInfo ? messageInfo.type : undefined}
        >
          {messageInfo ? messageInfo.message : undefined}
        </MuiAlert>
      </Snackbar>
    </Portal>
  )
}
