// ui action constants
import {
  UI_SET_DATE,
} from "../actions/types";
  
// default ui reducer
let date = new Date(Date.now())

// Add a day
date.setDate(date.getDate() + 2)

const defaultState = {
  currentDate: new Date(Date.now()).toLocaleString().split(',')[0],
  currentDatePlusOne: date.toLocaleString().split(',')[0],
}

// ui reducer
export default function init(state = defaultState, action) {
  switch (action.type) {

    case UI_SET_DATE:
      return {
        ...state,
        currentDate: action.payload,
        currentDatePlusOne: action.payload,
      }

    default:
      return state
  }
}
  