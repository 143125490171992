/* src/App.js */
import React from 'react'
import { useSelector } from 'react-redux';
import CustomDialog from "./components/customDialog/CustomDialog"
import Alert from "./components/alert/Alert"
import "./assets/scss/app.scss";
import Main from './components/Main';
import ActiveComponent from './components/activeComponent/ActiveComponent'

function App() {
  const activeComponent = useSelector((state) => state.ui.activeComponent.component);

  return (
    <div className="App">
      {activeComponent ? <ActiveComponent /> : <Main />}
      <CustomDialog />
      <Alert />
    </div>
  );
}

export default App;
