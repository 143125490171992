import React, { Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, CircularProgress } from '@material-ui/core'

import { closeCustomDialog } from '../../config/reduxstore/actions/ui/closeCustomDialog'

export default function CustomDialog() {
  const open = useSelector(state => state.ui.customDialog.open)
  const component = useSelector(state => state.ui.customDialog.component)
  const customDialogProps = useSelector(state => state.ui.customDialog.props)
  const dispatch = useDispatch()

  const loadComponent = component => {
    if (typeof component === 'string') {
      const ComponentToLoad = React.lazy(() => {
        return import(`../../components/${component}`)
      })

      return ComponentToLoad
    } else {
      return component
    }
  }

  const Form = () => {
    const FormComponent = loadComponent(component)
    return (
      <Suspense fallback={<CircularProgress />}>
        <FormComponent {...customDialogProps} />
      </Suspense>
    )
  }

  const toggleCustomDialog = event => {
    dispatch(closeCustomDialog())
  }

  return (
    <Dialog
      open={open}
      onClose={toggleCustomDialog}
    >
      <div>{component && <Form />}</div>
    </Dialog>
  )
}
