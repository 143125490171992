/*eslint-disable*/
import React from "react";
import Button from "../CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {
  Facebook,
  Twitter,
  Instagram,
  Edit,
  Streetview,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { openCustomDialog } from "../../../config/reduxstore/actions/ui/openCustomDialog"
import { setActiveComponent } from "../../../config/reduxstore/actions/ui/setActiveComponent"
import { tokenExpired } from '../../../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../../../config/utilities/localStorage"
import { setActiveAdminComponent } from "../../../config/reduxstore/actions/ui/setActiveAdminComponent"

import styles from "../../../assets/headerLinksStyle";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const handleContact = () => {
    dispatch(
      openCustomDialog("dialogs/Contact")
    )
  };

  const handleSetActiveComponent = (componentName) => {
    if (isAdmin && componentName === 'CurrentLocations') {
      clearLocalStorage()
      dispatch(tokenExpired())
      dispatch(
        setActiveAdminComponent(``)
      )
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    } else (
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    )
    props.handleDrawerToggle()
  };

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Contact"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Edit}
          dropdownList={[
            <Typography onClick={handleContact} className={classes.dropdownLink}>
              Contact Us
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Explore"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Streetview}
          dropdownList={[
            <Typography onClick={() => handleSetActiveComponent(`CurrentLocations`)} className={classes.dropdownLink}>
              Current Locations
            </Typography>,
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Merchandise"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={LocalOffer}
          dropdownList={[
            <Link to="/merchandise-shirts" className={classes.dropdownLink}>
              Shirts
            </Link>,
            <Link to="/merchandise-hats" className={classes.dropdownLink}>
              Hats
            </Link>
          ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/vixalopex"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <Twitter className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Facebook className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Instagram className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
