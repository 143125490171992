/* eslint-disable */
// user action constants
import {
    DISCLAIMER,
    CREATE_DISCLAIMER_SUCCESS,
    ALL_DISCLAIMERS_SUCCESS,
    UPDATE_DISCLAIMER_SUCCESS,
    DELETE_DISCLAIMER_SUCCESS
  } from "../actions/types";
  
  // default user state
  const defaultState = {
    disclaimer: {
      id: null,
      file_name: null,
      file_type: null,
      file_path: null
    },
    newDisclaimer: {},
    disclaimers: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case DISCLAIMER:
        return {
          ...state,
          disclaimer: {
            id: action.payload.id,
            file_name: action.payload.file_name,
            file_type: action.payload.file_type,
            file_path: action.payload.file_path
          }
        }

      case ALL_DISCLAIMERS_SUCCESS:
        return {
          ...state,
          disclaimers: action.payload
        }

      case CREATE_DISCLAIMER_SUCCESS:
        const existingArray = state.disclaimers;
        existingArray.push(action.payload);
        return {
          ...state,
          disclaimers: existingArray
        }
  
      case UPDATE_DISCLAIMER_SUCCESS:
        const filteredArray = state.disclaimers.filter(disclaimer => disclaimer.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          disclaimers: filteredArray,
        }

      case DELETE_DISCLAIMER_SUCCESS:
        const filteredData = state.disclaimers.filter(disclaimer => disclaimer.id !== action.payload)
        return {
          ...state,
          disclaimers: filteredData
        }
    

      default:
        return state;
    }
  };
  