// user action constants
import { UI_OPEN_CUSTOM_DIALOG } from '../types'

export const openCustomDialog = (component, props = {}) => ({
  type: UI_OPEN_CUSTOM_DIALOG,
  payload: {
    open: true,
    component,
    props
  }
})
