// hooks
import { useRef, useEffect } from 'react'

// useIsMounted custom hook
export const useIsMounted = () => {
  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true
  }, [])

  return isMountedRef.current
}
