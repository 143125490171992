// ui action constants
import {
  UI_SET_ALERT,
  UI_SET_CONTENT,
  UI_OPEN_CUSTOM_DIALOG,
  UI_CLOSE_CUSTOM_DIALOG,
  UI_SET_ACTIVE_COMPONENT,
  UI_SET_ACTIVE_ADMIN_COMPONENT
} from "../actions/types";

// default ui reducer
const defaultState = {
  alert: {
    type: '',
    message: ''
  },
  content: '',
  customDialog: {
    open: false,
    component: '',
    props: {}
  },
  activeComponent: {
    component: '',
    props: {}
  },
  activeAdminComponent: {
    component: '',
    props: {}
  },
}

// ui reducer
export default function init(state = defaultState, action) {
  switch (action.type) {

    case UI_SET_ALERT:
      return {
        ...state,
        alert: action.payload
      }

    case UI_SET_CONTENT:
      return {
        ...state,
        content: action.payload
      }

    case UI_OPEN_CUSTOM_DIALOG:
      return {
        ...state,
        customDialog: {
          open: action.payload.open,
          component: action.payload.component,
          props: action.payload.props
        }
      }
    
    case UI_CLOSE_CUSTOM_DIALOG:
      return {
        ...state,
        customDialog: {
          ...state.customDialog,
          open: action.payload.open
        }
      }

    case UI_SET_ACTIVE_COMPONENT:
      return {
        ...state,
        activeComponent: {
          component: action.payload.component,
          props: action.payload.props
        }
      }

    case UI_SET_ACTIVE_ADMIN_COMPONENT:
      return {
        ...state,
        activeAdminComponent: {
          component: action.payload.component,
          props: action.payload.props
        }
      }

    default:
      return state
  }
}
