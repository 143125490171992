/* eslint-disable */
// user action constants
import {
    SUBLOCATION,
    CREATE_SUBLOCATION_SUCCESS,
    SUBLOCATIONS_SUCCESS,
    UPDATE_SUBLOCATION_SUCCESS,
    DELETE_SUBLOCATION_SUCCESS
  } from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const locationId = getLocalStorage("locationId")
const subLocationId = getLocalStorage("subLocationId")
const subLocationNameLocal = getLocalStorage("subLocationName")
  
  // default user state
  const defaultState = {
    subLocationName: {
      id: null || subLocationId,
      name: null || subLocationNameLocal,
      location_id: null || locationId,
      city: null,
      state: null,
      lat: null,
      lng: null
    },
    newSubLocation: {},
    subLocations: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case SUBLOCATION:
        return {
          ...state,
          subLocationName: action.payload
        }

      case SUBLOCATIONS_SUCCESS:
        return {
          ...state,
          subLocations: action.payload
        }

      case CREATE_SUBLOCATION_SUCCESS:
        const existingArray = state.subLocations;
        existingArray.push(action.payload);
        return {
          ...state,
          newSubLocation: action.payload
        }
  
      case UPDATE_SUBLOCATION_SUCCESS:
        const filteredArray = state.subLocations.filter(sublocation => sublocation.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          subLocations: filteredArray,
        }

      case DELETE_SUBLOCATION_SUCCESS:
        const filteredData = state.subLocations.filter(sublocation => sublocation.id !== action.payload)
        return {
          ...state,
          subLocations: filteredData
        }
    

      default:
        return state;
    }
  };
  