import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import BaseViewRoute from '../Views/BaseViewRoute/BaseViewRoute'

export default function ActiveComponent() {
  const component = useSelector(state => state.ui.activeComponent.component)
  const activeComponentProps = useSelector(state => state.ui.activeComponent.props)

  const loadComponent = component => {
    if (typeof component === 'string') {
      const ComponentToLoad = React.lazy(() => {
        return import(`../../components/${component}`)
      })

      return ComponentToLoad
    } else {
      return component
    }
  }

  const Form = () => {
    const FormComponent = loadComponent(component)
    return (
      <Suspense fallback={<CircularProgress />}>
        <FormComponent {...activeComponentProps} />
      </Suspense>
    )
  }

  return (
    <BaseViewRoute component={component ? <Form /> : null} />
  )
}
