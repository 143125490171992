/* eslint-disable */
export default error => {
  let errorMessage = 'Something Went Wrong'

  if (error.msg) {
    errorMessage = error.msg.msg
    console.log(1, errorMessage)
  }

  if (error.message) {
    errorMessage = error.message
  }

  return errorMessage
}
