/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./customComponents/Header/Header.js";
import Footer from "./customComponents/Footer/Footer";
// import HeaderLinks from "./customComponents/Header/HeaderLinks.js";
import Parallax from "./customComponents/Parallax/Parallax.js";
import bg from "../assets/images/VixalopexEBS_4.png";
import Logo from "./customComponents/Logo/Logo.js";
import logoText from "../assets/images/EBS_text2.png"

import styles from "../assets/mainStyle.js";
import { Typography } from "@material-ui/core";
import Button from "./customComponents/CustomButtons/Button";
import VideoPlayer from "./Views/VideoPlayer.js";
import { setActiveComponent } from "../config/reduxstore/actions/ui/setActiveComponent"
import getLocations from "../config/requests/location/getLocations"
import { tokenExpired } from '../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../config/utilities/localStorage"
import { setActiveAdminComponent } from "../config/reduxstore/actions/ui/setActiveAdminComponent"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Main(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ...rest } = props;
  const [isLoading, setIsLoading] = useState(false)
  const isAdmin = useSelector((state) => state.user.isAdmin);

  useEffect(() => {
    getLocations(dispatch, setIsLoading)
  }, []);

  const handleSetActiveComponent = (componentName) => {
    if (isAdmin && componentName === 'CurrentLocations') {
      clearLocalStorage()
      dispatch(tokenExpired())
      dispatch(
        setActiveAdminComponent(``)
      )
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    } else (
      dispatch(
        setActiveComponent(`Views/${componentName}`)
      )
    )
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Logo />}
        // rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={bg}>
        <div className={classes.logoContainer}>
        <img style={{
          // flex: 1,
          mixBlendModde: "difference",
          color: "transparent",
          // filter: "drop-shadow(6px 2px 10px black)",
          width: "80%",
          paddingBottom: "25%",
          height: "30%",
          justifyContent: "center"
        }} src={logoText} alt="LogoName" />
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{padding: 40}}>
          <Typography gutterBottom variant="h5" component="div">
            Event Booking Services (EBS)
          </Typography>
          <Typography>
            EBS is a cloud based web application that allows customers to book event space.
          </Typography>
          <br />
            <Typography>To view available locations and event space for booking, please click on the Explore tab above or click on the button below.</Typography>
            <Button onClick={() => handleSetActiveComponent(`CurrentLocations`)}>
              Current Locations
            </Button>
        </div>
        <div className={classes.container}>
          <VideoPlayer />
        </div>
      </div>
      <Footer />
    </div>
  );
}
